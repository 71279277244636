import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['cardTitle']

  clickCardTitle() {
    window.location.href = this.cardTitleTarget.getAttribute('href')
  }
}
